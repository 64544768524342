<template>
  <Loading v-if="loadingActive"/>
  <InfoDialog v-if="modalActive" :modal-message="modalMessage" v-on:close-modal="closeInfoModal"/>
  <div class="container-fluid" style="background-color: var(--background-color); color: var(--text-color);">
    <div class="row">
      <div class="col-lg-2 p-0">
        <AdminSidebar />
      </div>
      <div class="col-12 col-lg-10 mx-auto mt-5 pt-2 p-1 m-0">
        <div class="header-bar shadow-sm pb-2">
          <div class="d-flex align-items-center">
            <button @click="$router.back()" class="btn me-2 float-start" style="color: var(--text-color);"><i class="fas fa-chevron-left"> </i></button>
            <span  class="fw-bolder">{{selectedSession || 'Live Session'}}</span> <span class="badge bg-warning rounded-pill">{{filterOrders.length}}</span>
<!--            <span class="mx-2 shadow-sm px-2 rounded-pill">
              {{selectedDate}}
            </span>-->
          </div>
          <div class="d-lg-flex align-items-center">
            <div class="d-flex my-1 rounded align-items-center p-1 search-box">
              <input type="text" v-model="search" @click="sessionInputToggle" placeholder="Search Session" class="form-control">
              <i class="fas fa-chevron-down mx-2" ></i>
            </div>
            <div class="session-pop-up shadow-sm p-2 "  v-if="toggleClick" >
              <button class="btn" style="color:var(--text-color);" @click="this.toggleClick = !this.toggleClick"><i class="fas fa-times"></i></button>
              <li v-for="(session, index) in filterSessions" :key="index" class=" my-1 list-unstyled rounded p-1 text-start" @click="showSessionProducts(session)">
                <span style="color: var(--text-color);"><i class="fas fa-calendar"></i>{{session.date.toDate().toLocaleDateString()}}</span>
                <div class="d-flex justify-content-between">
                  <span class="fw-bold">{{session.name}}</span>
                  <div>
                    <span class="badge bg-danger" v-if="session.status==='start'">Streaming</span>
                    <span class="badge bg-warning" v-if="session.status==='pending'">Pending</span>
                    <span class="badge bg-secondary" v-if="session.status==='ended'">Ended</span>
                  </div>
                </div>
              </li>
            </div>
            <div v-if="selectedSessionId!==''" class="d-flex justify-content-between align-items-center mx-1">

              <div class="btn-group mx-auto" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" class="btn-check" @click="showPending" name="btnradio" id="order-daily-1" autocomplete="off" :checked="selectedStatus==='pending'">
                <label class="btn check-btn btn-primary"  for="order-daily-1">Pending</label>

                <input type="radio" class="btn-check" @click="showConfirmed" name="btnradio" id="order-daily-2" autocomplete="off" :checked="selectedStatus==='confirmed'">
                <label class="btn check-btn btn-primary"  for="order-daily-2">Accepted</label>

                <input type="radio" class="btn-check" @click="showPaid" name="btnradio" id="order-daily-3" autocomplete="off" :checked="selectedStatus==='paid'">
                <label class="btn check-btn btn-primary" for="order-daily-3">Paid</label>

                <input type="radio" class="btn-check" @click="showCancelled" name="btnradio" id="order-daily-4" autocomplete="off" :checked="selectedStatus==='cancelled'">
                <label class="btn check-btn btn-primary" for="order-daily-4">Cancelled</label>
              </div>
            </div>
<!--            <div class="d-lg-flex d-md-flex justify-content-between align-items-center mx-1">
              &lt;!&ndash;          Day Picker &ndash;&gt;
              <div class="d-flex align-items-center search-box rounded" v-if="date_types==='day'">
                <button type="button" class="btn me-1 btn-sm d-flex align-items-center border rounded p-2 d-none" style="color: var(&#45;&#45;text-color);" @click="showVCalendar = !showVCalendar">
                  <i class="fas fa-calendar"></i> <span class="ms-1">Date</span>
                </button>
                <div v-if="showVCalendar" class="date-box position-fixed d-flex shadow flex-column rounded m-0 p-2 text-center" style="background-color: var(&#45;&#45;light);">
                  <div>
                    <button class="btn btn-close float-end" @click="showVCalendar =!showVCalendar"></button>
                  </div>
                  <Calendar :attributes="attributes" @dayclick="onDayClick" />
                  <div class="d-flex">
                    <button class="rounded btn mx-auto text-white btn-sm w-50 mt-1" style="background-color: var(&#45;&#45;primary);" @click="dateSelectHandler">OK</button>
                  </div>
                </div>
                <select class="form-select form-control mx-1" @change="onChange($event)" aria-label="Default select example">
                  <option value="All" selected>All</option>
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="paid">Paid</option>
                </select>
                <vue-excel-xlsx
                    :data="filterExportData"
                    :columns="columns"
                    :file-name="fileName"
                    :file-type="'xlsx'"
                    :sheet-name="fileName"
                    class="btn btn-success d-flex align-items-center p-2"
                >
                  <i class="fas fa-table"></i> <span class="ms-1"></span>
                </vue-excel-xlsx>
              </div>
              &lt;!&ndash;          Month Picker &ndash;&gt;

              <div class="d-flex align-items-center rounded p-2" v-if="date_types==='month'">
                <input type="month" @change="filterMonth" class="form-control">
                <div v-if="showVCalendar" class="date-box position-fixed d-flex shadow flex-column rounded m-0 p-2 text-center" style="background-color: var(&#45;&#45;light);">
                  <div>
                    <button class="btn btn-close float-end" @click="showVCalendar =!showVCalendar"></button>
                  </div>
                  <Calendar :attributes="attributes" @dayclick="onDayClick" />
                  <div class="d-flex">
                    <button class="rounded btn mx-auto text-white btn-sm w-50 mt-1" style="background-color: var(&#45;&#45;primary);" @click="dateSelectHandler">OK</button>
                  </div>
                </div>


              </div>
              &lt;!&ndash;          Year Picker &ndash;&gt;

              <div class="d-flex align-items-center rounded p-2" v-if="date_types==='year'">
                <NextDatePicker v-model:value="selectedYear" style="width: 100px;" @change="yearChange" type="year" placeholder="Select year"></NextDatePicker>

                <div v-if="showVCalendar" class="date-box position-fixed d-flex shadow flex-column rounded m-0 p-2 text-center" style="background-color: var(&#45;&#45;light);">
                  <div>
                    <button class="btn btn-close float-end" @click="showVCalendar =!showVCalendar"></button>
                  </div>
                  <Calendar :attributes="attributes" @dayclick="onDayClick" />
                  <div class="d-flex">
                    <button class="rounded btn mx-auto text-white btn-sm w-50 mt-1" style="background-color: var(&#45;&#45;primary);" @click="dateSelectHandler">OK</button>
                  </div>
                </div>
              </div>

            </div>-->
            <div class="d-flex my-1 ms-lg-1" v-if="selectedSessionId!==''" >
              <div class="d-flex align-items-center rounded p-1 search-box">
<!--                <select class="form-select form-control mx-1" @change="onChange($event)" aria-label="Default select example">
                  <option value="All" selected>All</option>
                  <option value="pending">Pending</option>
                  <option value="confirmed">Confirmed</option>
                  <option value="paid">Paid</option>
                </select>-->
                <button type="button" class="btn me-1 btn-sm d-flex w-75 align-items-center border rounded p-2" style="color: var(--text-color);background-color: var(--light);" @click="showVCalendar = !showVCalendar">
                  <i class="fas fa-calendar"></i> <span class="ms-1">Choose Date</span>
                </button>
                <div v-if="showVCalendar" class="date-box position-fixed d-flex shadow flex-column rounded m-0 p-2 text-center" style="background-color: var(--light);">
                  <div>
                    <button class="btn btn-close float-end" @click="showVCalendar =!showVCalendar"></button>
                  </div>
                  <Calendar :attributes="attributes" @dayclick="onDayClick" />
                  <div class="d-flex">
                    <button class="rounded btn mx-auto text-white btn-sm w-50 mt-1" style="background-color: var(--primary);" @click="dateSelectHandler">OK</button>
                  </div>
                </div>
                <vue-excel-xlsx
                    :data="filterExportData"
                    :columns="columns"
                    :file-name="fileName"
                    :file-type="'xlsx'"
                    :sheet-name="fileName"
                    class="btn btn-success d-flex w-25 p-2 justify-content-center align-items-center"
                >
                  <i class="fas fa-table"></i> <span class="ms-1"></span>
                </vue-excel-xlsx>
              </div>
<!--              <div class="d-flex align-items-center rounded p-1 search-box ms-1">
                <i class="fas fa-search mx-2"></i>
                <input type="text" class="form-control" v-model="search_orders" placeholder="search orders">
              </div>-->
            </div>

          </div>
          <div>
            <div class="d-flex align-items-center" v-if="days.length>0">
              <div style="overflow-x: scroll;overflow-y:hidden;width: 90%; height:30px;">
                <span class="mx-2 d-inline-block badge bg-secondary rounded-pill" v-for="(day,index) in days" :key="index">{{day.formattedDate}}</span>
                <button class="btn btn-warning btn-sm" v-if="days.length>0" @click="clearDays">
                  Clear days
                </button>
              </div>

            </div>
          </div>
        </div>

        <div class="card-body table-card table-responsive">
          <table class="table table-hover table-bordered">
            <thead>
            <tr>
              <th class="text-nowrap">#</th>
              <th class="text-nowrap">Order Id</th>
              <th class="text-nowrap">Status</th>
              <th class="text-nowrap">User Info</th>
              <th class="text-nowrap">Order Items</th>
              <th class="text-nowrap">Total Price</th>
              <th class="text-nowrap">Control</th>
              <th class="text-nowrap">Date</th>
            </tr>
            </thead>
            <tbody v-if="orders.length>0">
            <tr v-for="(order,index) in filterOrders" class="align-middle" :key="order.id">
              <td class="text-nowrap">
                {{index+1}}
              </td>
              <td class="text-nowrap">
                {{order.id}}
              </td>
              <td>
                <span class="badge rounded bg-warning" style="min-width: 100px;font-size: 12px;" v-if="order.orderStatus === 'pending'">{{order.orderStatus}}</span>
                <span class="badge rounded bg-primary" style="min-width: 100px;font-size: 12px;" v-else-if="order.orderStatus === 'confirmed'">{{order.orderStatus}}</span>
                <span class="badge rounded bg-success" style="min-width: 100px;font-size: 12px;" v-else-if="order.orderStatus === 'paid'">{{order.orderStatus}}</span>
                <span class="badge rounded bg-danger" style="min-width: 100px;font-size: 12px;" v-else>{{order.orderStatus}}</span>

              </td>
              <td class="d-flex flex-column align-middle text-nowrap">
                <span class="p-1 mb-1 rounded" style="color:var(--text-color);min-width: 120px;background-color: var(--light)"><i class="far fa-user"></i> {{order.user.name}}</span>
                <a :href="'tel:+959'+order.user.phone" v-if="order.user.phone!==''" class="p-1 rounded text-decoration-none" style="min-width: 120px;color:var(--text-color);background-color: var(--light)"><i class="fas fa-phone"></i>09 {{order.user.phone}}</a>
                <a :href="'tel:+959'+order.user.otherPhone" v-else class="p-1 rounded text-decoration-none" style="min-width: 120px;color:var(--text-color);background-color: var(--light)"><i class="fas fa-phone"></i>09 {{order.user.otherPhone}}</a>

              </td>
              <td class="text-nowrap">
                <div class="d-flex">
                  <!--                    <img :src="order.image" class="rounded rounded-circle" style="width:30px; height:30px; object-fit: cover;" alt="">-->
                  <span class="rounded mx-1 p-1 " style="background-color:var(--light);min-width: 70px;height:30px">{{order.itemName}}</span>
                  <span class="rounded mx-1 p-1" style="background-color:var(--light);min-width: 70px;height:30px"> {{ order.sellPrice }}</span>
                  <span class="rounded mx-1 p-1" style="background-color:var(--light);min-width: 70px;height:30px"> x {{order.count}}</span>
                </div>
              </td>
              <td class="text-nowrap">{{parseInt(order.liveSaleCount) * parseInt(order.sellPrice)}}</td>
              <td class="text-nowrap">
                <router-link :to="{name:'LiveOrderDetails',params:{order_id:order.id}}" class="btn" style="background-color: var(--softPrimary);"><i class="fas fa-info-circle"></i></router-link>
              </td>
              <td class="text-nowrap">
                <span>{{order.day}}</span> <br>
                <span>{{order.time.toDate().toLocaleTimeString()}}</span>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import db from "@/config/FirebaseInit";
import AdminSidebar from "@/components/AdminSidebar";
import Loading from "@/components/Loading";
import InfoDialog from "@/components/InfoDialog";
export default {
  //props from query route
  // props:['live_id','live_name','date_types','date'],
  props:['live_id','order_status'],
  components:{InfoDialog, Loading, AdminSidebar},
  data(){
    return{
      loadingActive:false,
      modalActive:false,
      modalMessage:'',
      selectedSession:'Choose Session',
      selectedSessionId:'',
      selectedSessionName:'',
      toggleClick:false,
      liveSessionData:[],
      orders:[],

      selectedDate:'',
      selectedStatus:'pending',
      selectedYear:'',
      showVCalendar : false,
      dateType:'day',
      filterData:[],
      days:[],
      search:'',
      fileName:'',
      range: {
        start: new Date(),
        end: new Date()
      },
      columns : [
        {
          label: "Order Id",
          field: "order-id"
        },
        {
          label: "Order Status",
          field: "orderStatus",
        },
        {
          label: "Customer Name",
          field: "customer-name",
        },
        {
          label: "Customer Phone",
          field: "customer-phone",
        },
        {
          label:"Item List",
          field: 'order-items'
        },
        {
          label:"Count List",
          field: 'order-counts'
        },
        {
          label:"Price List",
          field: 'order-prices'
        },
        {
          label:"Total Price",
          field: 'total-price'
        },

        {
          label: "Transaction Image",
          field: "transaction-img",
        },
        {
          label: "Delivery",
          field: "delivery-name",
        },
        {
          label: "Date",
          field: "date",
        },
        {
          label: "Time",
          field: "time",
        },
      ],
    }
  },
  computed:{
    owner(){
      return this.$store.getters.getShopOwner;
    },

    filterSessions(){
      if(this.search!==""){
        return this.liveSessionData.filter((session)=>{
          return session.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase());
        });

      }else {
        return this.liveSessionData;
      }
    },

    currentDay(){
      return this.$store.getters.getDay;
    },

    currentMonth(){
      return this.$store.getters.getMonth;
    },

    currentYear(){
      return this.$store.getters.getYear;
    },

    dates() {
      return this.days.map(day => day.date);
    },

    attributes() {
      return this.dates.map(date => ({
        highlight: true,
        dates: date,
      }));
    },

    filterOrders(){
      if(this.selectedStatus!==''){
        return this.orders.filter(order=>{
          return order.orderStatus === this.selectedStatus
        })
      }else if(this.search !==''){
        return this.orders.filter(order=>{
          return order.id.includes(this.search)|| order.user.name.includes(this.search) || order.user.phone.includes(this.search)
        })
      }
      else {
        return this.orders;
      }
    },
    filterExportData(){
      return this.filterData.filter(order=>{
        return order.orderStatus === this.selectedStatus
      })
    },
  },

  async mounted() {
    this.$store.dispatch("getDay");
    this.$store.dispatch("getMonth");
    this.$store.dispatch("getYear");
    this.fileName=this.selectedSession+'-live-orders';
    //await this.getAllSession();

    await db.collection(this.owner.city)
        .doc(this.owner.shopId)
        .collection('liveSession')
        .orderBy("date","desc")
        .onSnapshot((snapshot) => {
          this.liveSessionData = [];
          snapshot.forEach(doc => {
            this.liveSessionData.push(doc.data());
          });
        });
    //console.log("id exit",this.$route.params.live_id, this.$route.params.order_status)

    if(this.live_id!==''){
      this.selectedSessionId = this.$route.params.live_id;
      await db.collection(this.owner.city)
          .doc(this.owner.shopId)
          .collection('liveSession')
          .doc(this.selectedSessionId)
          .onSnapshot((snapshot) => {
            if(snapshot.exists){
              //console.log('snapshot',snapshot.data())
              this.selectedSession = snapshot.data().name;
              db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').where('liveSessionId','==',this.selectedSessionId).where('orderStatus','==',this.selectedStatus).onSnapshot(snapshot => {
                this.orders=[];
                this.filterData=[];
                this.filterOrders=[];

                snapshot.forEach(doc=>{
                  db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
                    this.orders.push({'user':userSnap.data(),...doc.data()})
                    this.addToFilterOrders(doc.data(), userSnap.data())
                  });
                })
              })
            }

          });
    }
  },
  methods:{
    clearDays(){
      this.days=[];
      // this.filterOrders=[];
      // this.filterData=[];
      // this.orders=[];
    },

    async sessionInputToggle(){
      this.toggleClick = !this.toggleClick;
    },
    closeInfoModal(){
      this.modalActive = !this.modalActive;
    },

    async showSessionProducts(session){
      this.selectedSessionId = session.id;
      this.selectedSession = session.name;
      this.toggleClick = !this.toggleClick;
      this.search='';
      this.dateType = 'day';
      this.selectedDate = this.currentDay;
      this.orders=[];
      this.filterOrders = [];
      this.filterData=[];
      this.fileName=this.currentDay+'-live-orders';
      this.$router.push({name:'LiveOrderList',params:{live_id:this.selectedSessionId, order_status:'pending'}})
      /*this.showOrders()*/
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').where('liveSessionId','==',this.selectedSessionId).where('orderStatus','==','pending').orderBy('time','desc').onSnapshot(snapshot => {
        snapshot.forEach(doc=>{
          db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
            this.orders.push({'user':userSnap.data(),...doc.data()})
            this.addToFilterOrders(doc.data(), userSnap.data())
          });
        })
      })

    },

    async onChange(e){
      this.search = '';
      this.selectedStatus = e.target.value;
    },

    /*async yearChange(){
      if(this.live_id!==''){
        let date = new Date(this.selectedYear)
        this.selectedDate = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        this.dateType = 'year'
        this.orders=[];
        this.filterOrders = [];

        this.filterData=[];
        this.fileName=this.selectedDate+'-live-orders';
        this.$router.push({name:'LiveOrderList',params:{live_id:this.selectedSessionId, live_name:this.selectedSession,date_types:this.dateType,date:this.selectedDate}});
        //this.showOrders();
        await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').where('liveSessionId','==',this.selectedSessionId).where('year','==',this.currentYear).orderBy('time','desc').onSnapshot(snapshot => {
          snapshot.forEach(doc=>{
            db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
              this.orders.push({'user':userSnap.data(),...doc.data()})
              this.addToFilterOrders(doc.data(), userSnap.data())
            });
          })
        })

      }else {
        this.modalActive = true;
        this.modalMessage =  'Please Select a live session';
      }
    },

    async filterMonth(e){
      if(this.live_id!==''){
        let date = new Date(e.target.value)
        let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
        this.selectedDate = month+"-"+year;
        this.fileName=this.selectedDate+'-live-orders';
        this.dateType = 'month';
        this.orders=[];
        this.filterOrders = [];
        this.filterData=[];
        this.fileName=this.selectedDate+'-live-orders';
        this.$router.push({name:'LiveOrderList',params:{live_id:this.selectedSessionId, live_name:this.selectedSession,date_types:'month',date:this.selectedDate}});

        await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').where('liveSessionId','==',this.selectedSessionId).where('month','==',this.selectedDate).orderBy('time','desc').onSnapshot(snapshot => {
          snapshot.forEach(doc=>{
            db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
              this.orders.push({'user':userSnap.data(),...doc.data()})
              this.addToFilterOrders(doc.data(), userSnap.data())
            });
          });
        //  console.log(this.orders);
        });
      }else {
        this.modalActive = true;
        this.modalMessage =  'Please Select a live session';
      }
    },*/

    dateSelectHandler(){
      this.filterData=[];
      this.fileName='live-orders';
      this.showVCalendar = !this.showVCalendar;
      this.orders = [];
      this.filterOrders = [];
      this.days.forEach(day=>{
         db.collection(this.owner.city)
            .doc(this.owner.shopId)
            .collection('liveOrders')
            .where('liveSessionId','==',this.selectedSessionId)
            .where('statusDay','==',day.formattedDate)
             .where('orderStatus','==',this.selectedStatus)
             .orderBy('time','desc')
            .onSnapshot(snapshot => {
              this.orders = [];
              snapshot.forEach(doc=>{
                db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
                  this.orders.push({'user':userSnap.data(),...doc.data()})
                })
              })
            })
      })
    },
    onDayClick(day) {
      const idx = this.days.findIndex(d => d.id === day.id);
      if (idx >= 0) {
        this.days.splice(idx, 1);
      } else {
        this.days.push({
              id:day.id,
              date:day.date,
              formattedDate:this.formatDate(day.date)
            }
        );
      }
    },
    formatDate(date){
      let year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
      let month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
      let day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
      return  day+"-"+month+"-"+year;
    },
    async showPending(){
      if(this.live_id!==''){
        this.selectedStatus = 'pending'
        this.orders=[];
        this.filterData=[];
        this.fileName=this.selectedStatus+'-live-orders';
        this.$router.push({name:'LiveOrderList',params:{live_id:this.selectedSessionId, order_status:this.selectedStatus}});
        await this.getLiveOrderByStatus();
      }else {
        this.modalActive = true;
        this.modalMessage =  'Please Select a live session';
      }
    },
    async showConfirmed(){
      if(this.live_id!==''){
        this.selectedStatus = 'confirmed'
        this.orders=[];
        this.filterData=[];
        this.fileName=this.selectedStatus+'-live-orders';
        this.$router.push({name:'LiveOrderList',params:{live_id:this.selectedSessionId, order_status:this.selectedStatus}});
        await this.getLiveOrderByStatus();
      }else {
        this.modalActive = true;
        this.modalMessage =  'Please Select a live session';
      }
    },
    async showPaid(){
      if(this.live_id!==''){
        this.selectedStatus = 'paid'
        this.orders=[];
        this.filterData=[];
        this.fileName=this.selectedStatus+'-live-orders';
        this.$router.push({name:'LiveOrderList',params:{live_id:this.selectedSessionId, order_status:this.selectedStatus}});
        await this.getLiveOrderByStatus();
      }else {
        this.modalActive = true;
        this.modalMessage =  'Please Select a live session';
      }

    },
    async showCancelled(){
     if(this.live_id!==''){
       this.selectedStatus = 'cancelled'
       this.orders=[];
       this.filterData=[];
       this.fileName=this.selectedStatus+'-live-orders';
       this.$router.push({name:'LiveOrderList',params:{live_id:this.selectedSessionId, order_status:this.selectedStatus}});
       await this.getLiveOrderByStatus();
     }else {
       this.modalActive = true;
       this.modalMessage =  'Please Select a live session';
     }
    },
    async getLiveOrderByStatus(){
      await db.collection(this.owner.city).doc(this.owner.shopId).collection('liveOrders').where('liveSessionId','==',this.selectedSessionId).where('orderStatus','==',this.selectedStatus).orderBy('time','desc').onSnapshot(snapshot => {
        snapshot.forEach(doc=>{
          db.collection('users').doc(doc.data().orderUserId).onSnapshot(userSnap=>{
            this.orders.push({'user':userSnap.data(),...doc.data()})
            this.addToFilterOrders(doc.data(), userSnap.data())
          });
        })
      })
    },
    addToFilterOrders(doc, userSnap){
      const exportData ={
        'order-id':doc.id,
        'orderStatus':doc.orderStatus,
        'customer-name':userSnap.name,
        'customer-phone':userSnap.phone+','+userSnap.otherPhone,
        'order-items':doc.itemName,
        'order-counts':doc.count,
        'order-prices':doc.sellPrice,
        'total-price':doc.totalPrice,
        'transaction-img':doc.transactionImg,
        'delivery-name':doc.deliveryServiceName,
        'date':doc.time.toDate().toLocaleDateString(),
        'time':doc.time.toDate().toLocaleTimeString()
      }
      this.filterData.push(exportData)
    },
  }
}
</script>

<style scoped>
thead, tbody{
  border: 1px solid var(--softPrimary) !important;
  color: var(--dark);
}
.header-bar{
  position: fixed;
  top:50px;
  background-color: var(--background-color) !important;
  width: 90%;
}
.date-box .card-header{
  background-color: white;
  box-shadow: none;
  border: none;
}
.date-box{
  margin:auto;
  top:160px;
}
/*button group*/
.btn-group .btn{
  border-radius: 0px !important;
}
.check-btn{
  border: 2px solid var(--primary);
  box-shadow: none !important;
  outline: none !important;
}
.check-btn:hover{
  background-color: var(--primary);
}
.btn-primary{
  background-color: white ;
  color: black;
}
.btn-primary:focus{
  background-color: var(--primary) !important;
}
/*End*/
.session-pop-up{
  z-index: 1000;
  background-color: var(--side-bar-bg);
  border: 2px solid white;
  position: absolute;
  overflow-y: scroll;
  width: 250px;
  height: 400px;
  border-radius: var(--border-radius) !important;
  padding-bottom: 50px;
  top: 100px;

}
li{
  cursor: pointer;
  background-color: var(--light);
}
li:hover{
  background-color: var(--softPrimary) !important;
}
.search-box{
  width: 200px;
  background-color: var(--side-bar-bg);
  padding: 5px;
}
.check-btn{
  width:100px;
}
.content-box{
  margin-top: 20px;
}
.table{
  font-size: 12px;
}
tr{
  color: var(--text-color);
}
.table-card{
  margin-top: 130px;
}
.rounded{
  border-radius: var(--border-radius) !important;
}
@media only screen and (max-width: 1030px) {
.header-bar{
  width: 80%;
}
}
@media only screen and (max-width: 900px) {


  .date-box{
    top: 250px;
  }
  .content-box {
    background-color: var(--light);
    margin-top: 60px;
  }
  .search-box{
    width:100%;
  }
  .session-pop-up{
    top:90px;
    width: 100%;
  }
  .header-bar{
    width: 98%;
  }
  .btn-group{
    width: 100%;
  }
  .table-card{
    margin-top: 220px;
  }
}
</style>